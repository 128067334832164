import axios from "axios";

const api = axios.create({
  baseURL: "https://cryptocasings-be-dev.herokuapp.com/api/v1",
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = token;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
