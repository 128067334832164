import React, { useEffect, useState } from "react";
import "./print.scss";
import logo from "../../resources/images/logo-transparent.png";
import QRCode from "react-qr-code";

//API
import { getBoxInfo } from "../../api/api";

//Utils
import { getDashedDate, getSlashedDate, getTime } from "../../resources/utils";

const Print = (props) => {
  const [data, setData] = useState("");
  const { params } = props.match;

  const fetchBoxInfo = async () => {
    try {
      const { data } = await getBoxInfo(params.id);
      console.log(data);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBoxInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      window.print();
    }
  }, [data]);

  return (
    <>
      <div className="container page-print h-100" id="to-print">
        <div className="row justify-content-center">
          <div className="col-12 mb-5">
            <div className="p-5">
              <img src={logo} alt="Logo" />
            </div>
            <p className="mt-3 text-center">
              <strong>Box Information Sheet</strong>
            </p>
          </div>
          <div
            className={
              data
                ? data.ffl_number || data.purchaser_driver_license_number
                  ? "col-6 mb-4"
                  : "col-12 mb-4"
                : ""
            }
          >
            <p className="text-center">
              <strong className="text-underline">Box ID</strong>
            </p>
            <p className="text-center">{data._id}</p>
          </div>
          {data ? (
            data.ffl_number ? (
              <div className="col-6 mb-4">
                <p className="text-center">
                  <strong className="text-underline">FFL Number</strong>
                </p>
                <p className="text-center">{data.ffl_number}</p>
              </div>
            ) : data.purchaser_driver_license_number ? (
              <div className="col-6 mb-4">
                <p className="text-center">
                  <strong className="text-underline">Driver's License</strong>
                </p>
                <p className="text-center">
                  {data.purchaser_driver_license_number}
                </p>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <div
            className={
              data ? (data.date_of_sale ? "col-6 mb-4" : "col-12 mb-4") : ""
            }
          >
            <p className="text-center">
              <strong className="text-underline">Manufacturing Date</strong>
            </p>
            <p className="text-center">
              {getDashedDate(data.manufacturing_date)}
            </p>
          </div>
          {data ? (
            data.date_of_sale ? (
              <div className="col-6 mb-4">
                <p className="text-center">
                  <strong className="text-underline">Sale Date</strong>
                </p>
                <p className="text-center">
                  {getDashedDate(data.date_of_sale)}
                </p>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div
            className={data ? (data.state ? "col-6 mb-4" : "col-12 mb-4") : ""}
          >
            <p className="text-center">
              <strong className="text-underline">Caliber</strong>
            </p>
            <p className="text-center">{data.caliber}</p>
          </div>
          {data ? (
            data.state ? (
              <div className="col-6 mb-4">
                <p className="text-center">
                  <strong className="text-underline">Sale State</strong>
                </p>
                <p className="text-center">{data.state}</p>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div className="col-12 text-center">
            <QRCode value={window.location.href} id="the-qr" size={250} />
          </div>
          <div className="col-12 mt-5 pt-5 text-center">
            <p>
              {getSlashedDate(new Date())} {getTime(new Date())}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Print;
