import React from "react";
import { Link } from "react-router-dom";
import "./register.scss";

const Roles = () => {
  return (
    <div className="container-fluid page page-signup-buttons">
      <div className="row justify-content-center h-100">
        <div className="col-xl-4 col-lg-6 col-md-8 col-11 my-auto"></div>
        <div className="col-12 text-center">
          <h1>Sign Up</h1>
          <h2>
            Are you a manufacturer or
            <br />
            investigator?
          </h2>
          <Link to="/sign-up/manufacturer">
            <button className="button-main w-100">Manufacturer</button>
          </Link>
          <Link to="/sign-up/investigator">
            <button className="button-main w-100">Investigator</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Roles;
