import React, { useState, useEffect } from "react";
import "./login.scss";
import { Col, Input, FormGroup, Label, FormFeedback, Alert } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

//API
import { userLogin, getCurrentUser } from "../../api/api";
import { handleAuthorizationHeader } from "../../api/helpers";

//Context
import withUser from "../../api/withContext/withUser";

const Login = ({ user, setUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    username: "",
    password: "",
  };

  const fetchUser = async () => {
    try {
      const { data } = await getCurrentUser();
      setUser(data);
      if (location.state) {
        history.push(location.state.prevLoc);
      } else {
        history.push("/directory");
      }
    } catch (e) {
      setUser(null);
      // if (location.pathname !== "/login" && location.pathname !== "/sign-up") {
      //   history.push("/");
      // }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      fetchUser();
    } else {
      if (location.state) {
        history.push(location.state.prevLoc);
      } else {
        history.push("/directory");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await userLogin(values);
      localStorage.setItem("token", `Bearer ${data.access_token}`);
      handleAuthorizationHeader();
      const userData = await getCurrentUser();
      setUser(userData.data);
      if (location.state) {
        history.push(location.state.prevLoc);
      } else {
        history.push("/directory");
      }
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || (user && location.pathname === "/login")) return "";

  return (
    <div className="container-fluid page page-login">
      <div className="row justify-content-center h-100">
        <div className="col-xl-4 col-lg-6 col-md-8 col-10 my-auto">
          <div className="login-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Formik className="pt-2">
                  <form disabled={isLoading}>
                    {error && (
                      <Alert color="warning" fade={false}>
                        Incorrect Email/Password
                      </Alert>
                    )}
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Label>
                          Email <span className="text-red">*</span>
                        </Label>
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.username && !!touched.username,
                          })}
                          placeholder=""
                          value={values.username}
                          onBlur={handleBlur("username")}
                          onChange={(e) => {
                            handleChange("username")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.username && !!errors.username}
                        />
                        <FormFeedback>
                          {touched.username && errors.username}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>
                          Password <span className="text-red">*</span>
                        </Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.password && !!touched.password,
                          })}
                          placeholder=""
                          value={values.password}
                          onBlur={handleBlur("password")}
                          onChange={(e) => {
                            handleChange("password")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.password && !!errors.password}
                        />
                        <FormFeedback>
                          {touched.password && errors.password}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col md="12" className="text-center mt-0">
                    <p>
                      <button
                        className="button-transparent"
                        onClick={() => setPass(true)}
                      >
                        Forgot your password?
                      </button>
                    </p>
                  </Col> */}
                    <FormGroup>
                      <Col md="12" className="text-center">
                        <button
                          type="submit"
                          color="primary"
                          block="true"
                          className="button-main"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          LOGIN
                        </button>
                      </Col>
                      {/* <Col md="12" className="text-center mt-2">
                        <p>
                          Don't have an account yet?{" "}
                          <button
                            className="button-transparent"
                            // onClick={() => history.push("/signup")}
                          >
                            Sign Up
                          </button>
                        </p>
                      </Col> */}
                    </FormGroup>
                  </form>
                </Formik>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUser(Login);
