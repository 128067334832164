import React, { useState, useEffect } from "react";
import "./directory.scss";
import {
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap";
import { MoreVertical, Camera, Upload, Edit, Download } from "react-feather";
import MUIDataTable from "mui-datatables";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";

//Assets
import bullet from "../../resources/images/bullet.png";

//Context
import withUser from "../../api/withContext/withUser";

//Components
import BulkAdd from "./modals/BulkAdd";
import BulkUpdate from "./modals/BulkUpdate";
import QRScanner from "../QRScanner";

//API
import { getMerchantBoxes } from "../../api/api";

const DataTable = ({ user }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [data, setData] = useState([]);
  const [qrValue, setqrValue] = useState("");
  const [qrSelected, setQrSelected] = useState([]);
  const [scanOpen, setScanOpen] = useState(false);

  const fetchBoxes = async () => {
    // setIsLoading(true);
    try {
      const { data } = await getMerchantBoxes();
      setData(data);
      // if (user.role ? user.role !== "investigator" : false) {
      //   let specificData = [];
      //   data.forEach((item) => {
      //     if (item.manufacturer.id === user._id) {
      //       specificData.push(item);
      //     }
      //   });
      //   setData(specificData);
      // } else {
      //   setData(data);
      // }
    } catch (e) {
      console.log(e);
    }
    //  finally {
    //   setIsLoading(false);
    // }
  };

  useEffect(() => {
    fetchBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns = [
    { name: "_id", label: "Box ID", width: 200 },
    {
      name: "manufacturer",
      label: "Manufacturer",
      width: 250,
      options: {
        customBodyRender: (value) => `${value.first_name} ${value.last_name}`,
      },
    },
    { name: "manufacturing_date", label: "Manufacturing Date", width: 200 },
    { name: "caliber", label: "Caliber", width: 150 },
    { name: "ffl_number", label: "FFL Number", width: 200 },
    {
      name: "purchaser_driver_license_number",
      label: "Driver's License Number",
      width: 250,
    },
    { name: "date_of_sale", label: "Sale Date", width: 200 },
    { name: "state", label: "Sale State", width: 150 },
    {
      name: "_id",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (value) =>
          value && (
            <UncontrolledDropdown>
              <DropdownToggle className="button-menu">
                <MoreVertical size={16} color="#fff" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => downloadQR(value)}>
                  <img src={bullet} alt="Bullet" /> Download QR Code
                </DropdownItem>
                {/* <DropdownItem>
                  <img src={bullet} alt="Bullet" /> Blockchain Link
                </DropdownItem> */}
                <DropdownItem
                  onClick={() => {
                    const win = window.open(`/directory/${value}`, "_blank");
                    win.focus();
                  }}
                >
                  <img src={bullet} alt="Bullet" /> Box Details
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
      },
    },
  ];

  if (user && user.manufacturer_type === "diyer") {
    columns.splice(4, 1);
    columns.splice(5, 1);
    columns[5].label = "State";
  }

  //Solution taken from: https://stackoverflow.com/questions/45498873/add-a-delay-after-executing-each-iteration-with-foreach-loop
  const downloadSelectedQRs = () => {
    let interval = 300;
    let promise = Promise.resolve();
    qrSelected.forEach((qr) => {
      promise = promise.then(function () {
        downloadQR(data[qr]._id);

        return new Promise(function (resolve) {
          setTimeout(resolve, interval);
        });
      });
    });

    // promise.then(function () {
    //   console.log("Loop finished.");
    // });
  };

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    responsive: "standard",
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => setQrSelected(rowsSelected),
  };

  const downloadQR = (value) => {
    setqrValue(`${window.location.href}/${value}`);

    htmlToImage
      .toJpeg(document.getElementById("the-qr"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `qr-box-${value}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <>
      <div className="hidden-qr">
        <QRCode value={qrValue} id="the-qr" size={250} />
      </div>
      <QRScanner
        {...{
          isOpen: scanOpen,
          toggle: () => setScanOpen(!scanOpen),
        }}
      />
      <BulkAdd
        {...{
          isOpen: addOpen,
          toggle: () => setAddOpen(!addOpen),
          fetchBoxes,
        }}
      />
      <BulkUpdate
        {...{
          isOpen: updateOpen,
          toggle: () => setUpdateOpen(!updateOpen),
          fetchBoxes,
        }}
      />
      <div className="container-fluid page page-directory">
        <Row className="wrapper-table">
          <Col xs="12">
            <MUIDataTable data={data} columns={columns} options={options} />
            <div className="footer-buttons">
              <ul>
                <li>
                  <button onClick={() => setScanOpen(true)}>
                    <span>Scan a QR Code </span>
                    <Camera />
                  </button>
                </li>
                {user && user.role !== "investigator" ? (
                  <>
                    <li>
                      <button onClick={() => setAddOpen(!addOpen)}>
                        <span>Bulk Add </span>
                        <Upload />
                      </button>
                    </li>
                    {/* <li>
                    <button>
                      Add <PlusSquare />
                    </button>
                  </li> */}
                    <li>
                      <button onClick={() => setUpdateOpen(!updateOpen)}>
                        <span>Bulk Update </span>
                        <Edit />
                      </button>
                    </li>
                  </>
                ) : (
                  ""
                )}

                <li>
                  <button onClick={() => downloadSelectedQRs()}>
                    <span>Download QR Codes </span>
                    <Download />
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withUser(DataTable);
