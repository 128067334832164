import React, { useState } from "react";
import "./header.scss";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Menu, User, LogOut } from "react-feather";

//Assets
import bullet from "../../resources/images/bullet.png";
import logo from "../../resources/images/logo-transparent.png";

//Context
import withUserContext from "../../api/withContext/withUser";

const Header = ({ user, setUser }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <div
        className={`header-main ${
          location.pathname === "/login" ||
          location.pathname === "/scan" ||
          location.pathname === "/"
            ? "show-bg"
            : ""
        } ${location.pathname.includes("print") ? "display-none" : ""}`}
      >
        <div className="container-fluid">
          <div
            className={`row ${
              location.pathname === "/login" || location.pathname === "/"
                ? "logo-centered"
                : ""
            }`}
          >
            <div className="col-5 col-md-7">
              <Link to="/">
                <img src={logo} id="logo" alt="Crypto Casings" />
              </Link>
            </div>
            {location.pathname !== "/login" && location.pathname !== "/" ? (
              <div className="col-7 col-md-5 user-buttons">
                {user ? (
                  <div>
                    <div>
                      <Link to="/account">
                        <User color="white" />
                      </Link>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          localStorage.removeItem("token");
                          setUser(null);
                          history.push("/");
                        }}
                      >
                        Log out&ensp;
                        <LogOut color="white" />
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <button
                  className="button-menu"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <Menu size={30} />
                </button> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={`sidebar-menu ${menuOpen ? "sidebar-show" : ""}`}>
        <button className="button-menu" onClick={() => setMenuOpen(!menuOpen)}>
          <Menu size={30} />
        </button>
        <ul>
          <li>
            <img src={bullet} alt="Bullet" />
            <Link to="/"> Add New Record</Link>
          </li>
          <li>
            <img src={bullet} alt="Bullet" />
            <Link to="/admin/records"> Admin Panel</Link>
          </li>
          <li>
            <img src={bullet} alt="Bullet" />
            <Link to="/"> Scan a QR Code</Link>
          </li>
          <li>
            <img src={bullet} alt="Bullet" />
            <button
              onClick={() => {
                localStorage.removeItem("token");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            >
              {" "}
              Log Out
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default withUserContext(Header);
