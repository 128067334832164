import React from "react";
import withUserContext from "../../api/withContext/withUser";

//Components
import Investigator from "./components/Investigator";
import Manufacturer from "./components/Manufacturer";

const Accounts = ({ user }) => {
  if (user && user.role === "investigator") return <Investigator />;

  return <Manufacturer />;
};

export default withUserContext(Accounts);
