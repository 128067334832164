import React, { useState } from "react";
import "./forgotpassword.scss";

//Components
import ForgotPasswordForm from "./components/Form";
import Confirm from "./components/Confirm";

const ForgotPassword = () => {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <div className="container-fluid page page-forgotpassword">
      <div className="row justify-content-center h-100">
        <div className="col-xl-6 col-lg-8 col-md-8 col-11 my-auto">
          {!emailConfirmed && (
            <ForgotPasswordForm {...{ setEmailConfirmed, setEmail }} />
          )}
          {emailConfirmed && <Confirm {...{ email }} />}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
