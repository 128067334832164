import React from "react";
import "../admin.scss";
import { Row, Col, Table } from "reactstrap";

const Records = (props) => {
  // const [filter, setFilter] = useState("Filter");
  // const [isLoading, setIsLoading] = useState(false);
  // const [searchText, setSearchText] = useState("");
  // const handleClick = () => {};

  const mapRecords = () => {
    const arr = [
      {
        manufacturer: "Winchester",
        manufacturerDate: "Community",
        caliber: ".45",
        soldDate: "12-26-2020",
        driversLicense: "123456789",
        ffl: "123456789",
        state: "TN",
        sold: false,
      },
      {
        manufacturer: "Winchester",
        manufacturerDate: "Community",
        caliber: ".45",
        soldDate: "12-26-2020",
        driversLicense: "123456789",
        ffl: "123456789",
        state: "TN",
        sold: true,
      },
    ];

    return arr.map((item, index) => (
      <tr key={index}>
        <td>{item.manufacturer}</td>
        <td>{item.manufacturerDate}</td>
        <td>{item.caliber}</td>
        <td>{item.sold ? <p className="sold-date">SOLD</p> : item.soldDate}</td>
        <td>{item.driversLicense}</td>
        <td>{item.ffl}</td>
        <td>{item.state}</td>
      </tr>
    ));
  };

  return (
    <Row className="table-records">
      <Col className="px-0 pt-0">
        <Table hover>
          <thead>
            <tr>
              <th>Manufacturer</th>
              <th>Manufacture Date</th>
              <th>Caliber</th>
              <th>Sold On</th>
              <th>DL #</th>
              <th>FFL #</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>{mapRecords()}</tbody>
        </Table>
        <button className="button-add">Add</button>
      </Col>
    </Row>
  );
};

export default Records;
