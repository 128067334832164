import React, { useEffect, useState } from "react";
import "./details.scss";
import QRCode from "react-qr-code";
import { Camera } from "react-feather";
import { Link } from "react-router-dom";

//API
import { getBoxInfo } from "../../api/api";

//Components
import QRScanner from "../QRScanner";

const BoxDetails = (props) => {
  const [data, setData] = useState("");
  const [scannerOpen, setScannerOpen] = useState(false);
  const { params } = props.match;

  const fetchBoxInfo = async () => {
    try {
      const { data } = await getBoxInfo(params.id);
      console.log(data);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  // const downloadQR = () => {
  //   htmlToImage
  //     .toJpeg(document.getElementById("the-qr"), { quality: 0.95 })
  //     .then(function (dataUrl) {
  //       var link = document.createElement("a");
  //       link.download = `qr-box-${data._id}.jpeg`;
  //       link.href = dataUrl;
  //       link.click();
  //     });
  // };

  useEffect(() => {
    fetchBoxInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QRScanner
        {...{
          isOpen: scannerOpen,
          toggle: () => setScannerOpen(!scannerOpen),
        }}
      />
      <div className="container-fluid page page-details">
        <div className="row justify-content-center">
          <div className="col-11 border-wrapper">
            <hr />
          </div>
          <div className="col-xl-10 col-11">
            {data ? (
              <>
                <div
                  className={`row justify-content-center details-container `}
                >
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-11 info-wrapper">
                    <h3>Box ID</h3>
                    <p>{data._id}</p>

                    <h3>Manufacturing Date</h3>
                    <p>{data.manufacturing_date}</p>

                    <h3>Caliber</h3>
                    <p>{data.caliber}</p>
                  </div>
                  {data.ffl_number || data.purchaser_driver_license_number ? (
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-11 info-wrapper">
                      {data.ffl_number ? (
                        <>
                          <h3>FFL Number</h3>
                          <p>{data.ffl_number}</p>
                        </>
                      ) : data.purchaser_driver_license_number ? (
                        <>
                          <h3>Driver's License Number</h3>
                          <p>{data.purchaser_driver_license_number}</p>
                        </>
                      ) : (
                        ""
                      )}
                      {data.date_of_sale ? (
                        <>
                          <h3>Sale Date</h3>
                          <p>{data.date_of_sale}</p>
                        </>
                      ) : (
                        ""
                      )}

                      {data.state ? (
                        <>
                          <h3>{data.date_of_sale ? "Sale " : ""}State</h3>
                          <p>{data.state}</p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12 qr-container">
                    <div id="qr-wrapper">
                      <QRCode
                        value={window.location.href}
                        id="the-qr"
                        size={250}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-11 info-wrapper sm-hidden"></div>
                  {data.ffl_number || data.purchaser_driver_license_number ? (
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-11 info-wrapper sm-hidden"></div>
                  ) : (
                    ""
                  )}

                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12 qr-container">
                    {/* <div className="mt-5 text-center">
                      <button
                        className="button-submain"
                        onClick={() => downloadQR()}
                      >
                        Download as PDF
                      </button>
                    </div> */}
                    <div className="mt-4 text-center">
                      <Link to={`/print/${params.id}`} className="button-submain">
                        Print
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="row justify-content-center mt-5 mb-3">
              <div className="col-xl-7 col-lg-10 col-11 text-center">
                <hr />
                <button
                  className="footer-button"
                  onClick={() => setScannerOpen(true)}
                >
                  <span>Scan a QR Code </span>
                  <Camera />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxDetails;
