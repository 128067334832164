import api from "./index";

//USERS
export const getCurrentUser = () => {
  return api.get(`/users/me`);
};

export const userLogin = (values) => {
  const login_data = new FormData();

  for (let key in values) {
    login_data.append(key, values[key]);
  }

  return api.post(`/users/token`, login_data);
};

export const getUsers = () => {
  return api.get(`/users`);
};

export const createUser = (values) => {
  return api.post(`/users`, values);
};

export const updateUser = (id, values) => {
  return api.patch(`/users/${id}`, values);
};

export const changePassword = (id, values) => {
  return api.patch(`/users/${id}/password`, values);
};

export const forgotPassword = (values) => {
  return api.patch(`/users/forgot-password`, values);
};

//BOXES
export const createBox = (values) => {
  return api.post(`/boxes`, values);
};

export const bulkCreateBox = (values) => {
  return api.post(`/boxes/bulk-add`, values);
}

export const getBoxes = () => {
  return api.get(`/boxes`);
};

export const getBoxInfo = (values) => {
  return api.get(`/boxes/${values}`);
};

export const getMerchantBoxes = () => {
  return api.get(`/boxes/merchant`);
}

export const updateBoxInfo = (id, values) => {
  return api.put(`/boxes/${id}`, values);
};