import React, { useState } from "react";
import "../account.scss";
import { User, Edit } from "react-feather";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Alert, Row, Col, FormGroup, Input, FormFeedback } from "reactstrap";

//Context
import withUserContext from "../../../api/withContext/withUser";

//API
import { updateUser } from "../../../api/api";

const Account = ({ user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState({
    editing: false,
    first_name: false,
    last_name: false,
    phone_number: false,
    email: false,
  });
  const [success, setSuccess] = useState(false);

  const setEdits = (key, value) => {
    let obj = isEditing;
    obj.editing = true;
    obj[key] = value;
    setIsEditing({ ...obj });
  };

  const UpdateSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    phone_number: Yup.string().required("Please enter a valid phone number"),
  });

  let initialValues = {
    first_name: user && user.first_name ? user.first_name : "",
    last_name: user && user.last_name ? user.last_name : "",
    email: user && user.email ? user.email : "",
    phone_number: user && user.phone_number ? user.phone_number : "",
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      const res = await updateUser(user._id, values);
      if (res) {
        setIsEditing({
          editing: false,
          first_name: false,
          last_name: false,
          phone_number: false,
          email: false,
        });
        setSuccess(true);
        setUser(res.data);
      }
    } catch (e) {
      if (e.response.data.detail === "Existing email address") {
        setError(
          "The email you entered is already in use. Please use a different email."
        );
      } else {
        setError("Something went wrong. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid page page-account">
      <div className="row justify-content-center h-100">
        <div className="col-xl-5 col-lg-6 col-md-8 col-11 my-auto text-center">
          <div>
            <User color="white" size="50" />
          </div>
          <h2>Account Page</h2>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={UpdateSchema}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <Formik className="pt-2">
                <form disabled={isLoading}>
                  {success && (
                    <Alert color="success" fade={false}>
                      Changes saved!
                    </Alert>
                  )}
                  {error && (
                    <Alert color="warning" fade={false}>
                      {error}
                    </Alert>
                  )}
                  <div className="mt-3 mt-sm-5">
                    <div className="user-info">
                      {isEditing.email ? (
                        <Row className="justify-content-center">
                          <Col xs={10}>
                            <FormGroup>
                              <p className="mb-0">Email:</p>
                              <Input
                                id="email"
                                name="email"
                                type="text"
                                required
                                className={classNames("form-control", {
                                  "login-warning text-center":
                                    !!errors.email && !!touched.email,
                                })}
                                placeholder=""
                                value={values.email}
                                onBlur={handleBlur("email")}
                                onChange={(e) => {
                                  handleChange("email")(e);
                                  setError(false);
                                }}
                                disabled={isLoading}
                                invalid={!!touched.email && !!errors.email}
                              />
                              <FormFeedback>
                                {touched.email && errors.email}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <p>
                          Email:
                          <br />
                          {user && `${user.email}`}
                        </p>
                      )}
                      {!isEditing.email ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSuccess(false);
                            setError("");
                            setEdits("email", true);
                          }}
                        >
                          <Edit color="white" size="30" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="user-info">
                      {isEditing.first_name ? (
                        <Row className="justify-content-center">
                          <Col xs={10}>
                            <FormGroup>
                              <p className="mb-0">First Name:</p>
                              <Input
                                id="first_name"
                                name="first_name"
                                type="text"
                                required
                                className={classNames("form-control", {
                                  "login-warning text-center":
                                    !!errors.first_name && !!touched.first_name,
                                })}
                                placeholder=""
                                value={values.first_name}
                                onBlur={handleBlur("first_name")}
                                onChange={(e) => {
                                  handleChange("first_name")(e);
                                  setError(false);
                                }}
                                disabled={isLoading}
                                invalid={
                                  !!touched.first_name && !!errors.first_name
                                }
                              />
                              <FormFeedback>
                                {touched.first_name && errors.first_name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <p>
                          First Name:
                          <br />
                          {user && `${user.first_name}`}
                        </p>
                      )}
                      {!isEditing.first_name ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSuccess(false);
                            setError("");
                            setEdits("first_name", true);
                          }}
                        >
                          <Edit color="white" size="30" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="user-info">
                      {isEditing.last_name ? (
                        <Row className="justify-content-center">
                          <Col xs={10}>
                            <FormGroup>
                              <p className="mb-0">Last Name:</p>
                              <Input
                                id="last_name"
                                name="last_name"
                                type="text"
                                required
                                className={classNames("form-control", {
                                  "login-warning text-center":
                                    !!errors.last_name && !!touched.last_name,
                                })}
                                placeholder=""
                                value={values.last_name}
                                onBlur={handleBlur("last_name")}
                                onChange={(e) => {
                                  handleChange("last_name")(e);
                                  setError(false);
                                }}
                                disabled={isLoading}
                                invalid={
                                  !!touched.last_name && !!errors.last_name
                                }
                              />
                              <FormFeedback>
                                {touched.last_name && errors.last_name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <p>
                          Last Name:
                          <br />
                          {user && `${user.last_name}`}
                        </p>
                      )}
                      {!isEditing.last_name ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSuccess(false);
                            setError("");
                            setEdits("last_name", true);
                          }}
                        >
                          <Edit color="white" size="30" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {user && user.role === "investigator" ? (
                      <div className="user-info">
                        {isEditing.phone_number ? (
                          <Row className="justify-content-center">
                            <Col xs={10}>
                              <FormGroup>
                                <p className="mb-0">Phone Number:</p>
                                <Input
                                  id="phone_number"
                                  name="phone_number"
                                  type="text"
                                  required
                                  className={classNames("form-control", {
                                    "login-warning text-center":
                                      !!errors.phone_number &&
                                      !!touched.phone_number,
                                  })}
                                  placeholder=""
                                  value={values.phone_number}
                                  onBlur={handleBlur("phone_number")}
                                  onChange={(e) => {
                                    handleChange("phone_number")(e);
                                    setError(false);
                                  }}
                                  disabled={isLoading}
                                  invalid={
                                    !!touched.phone_number &&
                                    !!errors.phone_number
                                  }
                                />
                                <FormFeedback>
                                  {touched.phone_number && errors.phone_number}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <p>
                            Phone Number:
                            <br />
                            {user && `${user.phone_number}`}
                          </p>
                        )}
                        {!isEditing.phone_number ? (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setSuccess(false);
                              setError("");
                              setEdits("phone_number", true);
                            }}
                          >
                            <Edit color="white" size="30" />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <Link
                      to="/account/change-password"
                      className="button-submain"
                    >
                      Change Password
                    </Link>
                  </div>
                  <div className="button-save">
                    <button
                      type="submit"
                      className="button-main"
                      disabled={isLoading || !isEditing.editing}
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Formik>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(Account);
