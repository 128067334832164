import React from "react";
import "./scan.scss";

const Scan = () => {
  return (
    <div className="container-fluid page page-scan h-100vh">
      <div className="row justify-content-center h-100">
        <div className="col-xl-5 col-lg-8 col-md-8 col-11 my-auto">
          <div className="scan-wrapper text-center">
            <button className="button-main">Scan Casing</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scan;
