import React from "react";
import "../admin.scss";
import { Row, Col, Table } from "reactstrap";

const Requests = (props) => {
  // const [filter, setFilter] = useState("Filter");
  // const [isLoading, setIsLoading] = useState(false);
  // const [searchText, setSearchText] = useState("");
  // const handleClick = () => {};

  return (
    <Row className="table-admin">
      <Col className="px-0 pt-0">
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rick</td>
              <td>rick123@yahoo.com</td>
              <td>6192093814</td>
              <td className="user-button">
                <button>Approve</button>
                <button>Deny</button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default Requests;
