import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

//Pages
import Home from "../pages/Home";
import Login from "../pages/Login";
// import Search from "../pages/Search";
import Scan from "../pages/Scan";
import Admin from "../pages/Admin";
import Sale from "../pages/Sale";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import Account from "../pages/Account";
import ChangePassword from "../pages/Account/components/ChangePassword";
import DataTable from "../pages/Directory";
import BoxDetails from "../pages/BoxDetails";
import Print from "../pages/Print";

//Registration Pages
import InvestigatorRegistration from "../pages/Register/components/Investigator";
import ManufacturerButtons from "../pages/Register/components/Manufacturer";
import ManufacturerResale from "../pages/Register/components/Manufacturer/Resale";
import ManufacturerPersonal from "../pages/Register/components/Manufacturer/Personal";

//Context
import withAuthentication from "../api/withContext/withUser";

//API
import { getCurrentUser } from "../api/api";

const Routes = ({ user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const fetchUser = async () => {
    try {
      const { data } = await getCurrentUser();
      setUser(data);
    } catch (e) {
      setUser(null);
      if (location.pathname !== "/login" && location.pathname !== "/sign-up") {
        if (location.pathname !== "/") {
          history.push({
            pathname: "/",
            state: {
              prevLoc: location.pathname,
            },
          });
        } else {
          history.push("/");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      fetchUser();
      setIsLoading(true);
    } else {
      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname.includes("sign-up")
      ) {
        history.push("/directory");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (isLoading) return "";

  return (
    <Switch>
      <Route exact component={Home} path="/" />
      <Route
        exact
        component={Login}
        path="/login"
        key={document.location.href}
      />
      <Route exact component={Register} path="/sign-up" />
      <Route
        exact
        component={InvestigatorRegistration}
        path="/sign-up/investigator"
      />
      <Route
        exact
        component={ManufacturerButtons}
        path="/sign-up/manufacturer"
      />
      <Route
        exact
        component={ManufacturerResale}
        path="/sign-up/manufacturer/resale"
      />
      <Route
        exact
        component={ManufacturerPersonal}
        path="/sign-up/manufacturer/personal"
      />
      <Route exact component={ForgotPassword} path="/forgot-password" />
      {/* <Route exact component={Search} path="/search" /> */}
      <Route exact component={Scan} path="/scan" />
      <Route exact component={Admin} path="/admin/:category" />
      <Route exact component={Sale} path="/sale" />
      <Route exact component={Account} path="/account" />
      <Route exact component={ChangePassword} path="/account/change-password" />
      <Route exact component={DataTable} path="/directory" />
      <Route exact component={BoxDetails} path="/directory/:id" />
      <Route exact component={Print} path="/print/:id" />
      <Redirect from="/admin" to="/admin/records" />
    </Switch>
  );
};

export default withAuthentication(Routes);
