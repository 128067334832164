import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CSVReader } from "react-papaparse";
import { Upload, X } from "react-feather";
import "../directory.scss";

//Context
import withUserContext from "../../../api/withContext/withUser";

//API
import { bulkCreateBox } from "../../../api/api";

//Assets
import loader from "../../../resources/images/loader.gif";

const BulkAdd = ({ isOpen, toggle, user, fetchBoxes }) => {
  const [data, setData] = useState("");
  const [added, setAdded] = useState(0);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const clear = () => {
    toggle();
    setData("");
    setError(false);
  };

  const getDate = (date) => {
    const newDate = new Date(date);

    return `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()}`;
  };

  const structureData = (data) => {
    let structuredData = [];
    console.log("To structure:", data);

    if (user.manufacturer_type === "reseller") {
      structuredData = data.map((item, index) => {
        return {
          manufacturing_date: getDate(data[index].data[0]),
          caliber: parseFloat(data[index].data[1]).toFixed(2),
        };
      });
    } else {
      structuredData = data.map((item, index) => {
        return {
          manufacturing_date: getDate(data[index].data[0]),
          caliber: parseFloat(data[index].data[1]).toFixed(2),
          purchaser_driver_license_number: data[index].data[2],
          state: data[index].data[3],
        };
      });
    }

    structuredData.splice(0, 1);
    structuredData.splice(structuredData.length - 1, 1);

    uploadData(structuredData);
  };

  const uploadData = async (data) => {
    setIsLoading(true);

    try {
      let upload = await bulkCreateBox(data);

      if (upload.length) {
        setAdded(data.length);
        fetchBoxes();
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal {...{ isOpen }} toggle={() => clear()} centered>
      <ModalBody className="bulk-add-container">
        <button
          className="button-exit"
          onClick={() => {
            toggle();
            setData("");
          }}
        >
          <X size={30} />
        </button>
        {error ? (
          <div className="p-4">
            <h5 className="text-red">Error!</h5>
            <h5>
              Some of this data was not entered into the system as it does not
              match the required format.
            </h5>
            <button className="modal-button mt-3" onClick={() => clear()}>
              Close
            </button>
          </div>
        ) : (
          ""
        )}
        {!data ? (
          <CSVReader
            onFileLoad={(data, file) => {
              setData(data);
              structureData(data);
              setFileName(file.name);
            }}
            style={{
              dropArea: {
                borderColor: "#635959",
                borderRadius: `4px`,
              },
              dropAreaActive: {
                borderColor: "red",
              },
            }}
          >
            <div className="dropzone-wrapper">
              <Upload color="black" size={30} />
              <p className="mt-2">Drag and drop CSV file here</p>
              <p>Or</p>
              <button className="modal-button">Browse Files</button>
            </div>
          </CSVReader>
        ) : !error ? (
          <div className="p-4">
            {isLoading ? (
              <a
                href="https://loading.io/"
                target="_blank"
                rel="noreferrer"
                title="Provided by loading.io"
              >
                <img src={loader} alt="Loading..." />
              </a>
            ) : (
              <>
                <h5>Upload Successful!</h5>
                <h5>{added} boxes created.</h5>
                <p>{fileName}</p>
                <button className="modal-button mt-3" onClick={() => clear()}>
                  Close
                </button>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </ModalBody>
    </Modal>
  );
};

export default withUserContext(BulkAdd);
