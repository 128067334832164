import React, { useState } from "react";
import "../../../register.scss";
import {
  Col,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Alert,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

//Components
import Success from "../../Success";
import { createUser } from "../../../../../api/api";

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const SignupSchema = Yup.object().shape({
    physical_address: Yup.string().required(
      "Company physical address is required"
    ),
    first_name: Yup.string().required("Given name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    driver_license_number: Yup.string().required("FFL number is required"),
    driver_license_state: Yup.string().required("License State is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password must match"
        )
        .required("Confirm Password is required"),
    }),
  });

  const initialValues = {
    physical_address: "",
    first_name: "",
    last_name: "",
    email: "",
    driver_license_number: "",
    driver_license_state: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    let { confirmPassword, ...formValues } = values;
    formValues = {
      ...formValues,
      role: "manufacturer",
      manufacturer_type: "diyer",
    };

    console.log(formValues);

    try {
      const res = await createUser(formValues);
      console.log(res);

      setSuccess(true);
    } catch (e) {
      if (e.response.data.detail === "Existing email address") {
        setError(
          "The email you entered is already in use. Please use a different email."
        );
      } else {
        setError("Something went wrong. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (success) return <Success />;

  return (
    <div className="container-fluid page page-signup">
      <div className="row justify-content-center h-100">
        <div className="col-xl-8  col-lg-6 col-md-8 col-9 my-auto">
          <h1 className="text-white text-center mb-2 mb-md-5">
            Sign Up - Personal
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <Formik className="pt-2">
                <form disabled={isLoading}>
                  {error && (
                    <Alert color="warning" fade={false}>
                      Something went wrong. Please try again.
                    </Alert>
                  )}
                  <Row className="justify-content-around">
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="first_name"
                          name="first_name"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.first_name && !!touched.first_name,
                          })}
                          placeholder=""
                          value={values.first_name}
                          onBlur={handleBlur("first_name")}
                          onChange={(e) => {
                            handleChange("first_name")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.first_name && !!errors.first_name}
                        />
                        <Label>Given Name</Label>
                        <FormFeedback>
                          {touched.first_name && errors.first_name}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="last_name"
                          name="last_name"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.last_name && !!touched.last_name,
                          })}
                          placeholder=""
                          value={values.last_name}
                          onBlur={handleBlur("last_name")}
                          onChange={(e) => {
                            handleChange("last_name")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.last_name && !!errors.last_name}
                        />
                        <Label>Last Name</Label>
                        <FormFeedback>
                          {touched.last_name && errors.last_name}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="11" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="physical_address"
                          name="physical_address"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.physical_address &&
                              !!touched.physical_address,
                          })}
                          placeholder=""
                          value={values.physical_address}
                          onBlur={handleBlur("physical_address")}
                          onChange={(e) => {
                            handleChange("physical_address")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={
                            !!touched.physical_address &&
                            !!errors.physical_address
                          }
                        />
                        <Label>Physical Address</Label>
                        <FormFeedback>
                          {touched.physical_address && errors.physical_address}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="driver_license_number"
                          name="driver_license_number"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.driver_license_number &&
                              !!touched.driver_license_number,
                          })}
                          placeholder=""
                          value={values.driver_license_number}
                          onBlur={handleBlur("driver_license_number")}
                          onChange={(e) => {
                            handleChange("driver_license_number")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={
                            !!touched.driver_license_number &&
                            !!errors.driver_license_number
                          }
                        />
                        <Label>Driver's License Number</Label>
                        <FormFeedback>
                          {touched.driver_license_number &&
                            errors.driver_license_number}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="driver_license_state"
                          name="driver_license_state"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.driver_license_state &&
                              !!touched.driver_license_state,
                          })}
                          placeholder=""
                          value={values.driver_license_state}
                          onBlur={handleBlur("driver_license_state")}
                          onChange={(e) => {
                            handleChange("driver_license_state")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={
                            !!touched.driver_license_state &&
                            !!errors.driver_license_state
                          }
                        />
                        <Label>Driver's License State</Label>
                        <FormFeedback>
                          {touched.driver_license_state &&
                            errors.driver_license_state}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="11" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          required
                          className={classNames("form-control", {
                            "login-warning": !!errors.email && !!touched.email,
                          })}
                          placeholder=""
                          value={values.email}
                          onBlur={handleBlur("email")}
                          onChange={(e) => {
                            handleChange("email")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.email && !!errors.email}
                        />
                        <Label>Email</Label>
                        <FormFeedback>
                          {touched.email && errors.email}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.password && !!touched.password,
                          })}
                          placeholder=""
                          value={values.password}
                          onBlur={handleBlur("password")}
                          onChange={(e) => {
                            handleChange("password")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={!!touched.password && !!errors.password}
                        />
                        <Label>Password</Label>
                        <FormFeedback>
                          {touched.password && errors.password}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="5" className="mb-2 mb-md-4">
                      <FormGroup>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          required
                          className={classNames("form-control", {
                            "login-warning":
                              !!errors.confirmPassword &&
                              !!touched.confirmPassword,
                          })}
                          placeholder=""
                          value={values.confirmPassword}
                          onBlur={handleBlur("confirmPassword")}
                          onChange={(e) => {
                            handleChange("confirmPassword")(e);
                            setError(false);
                          }}
                          disabled={isLoading}
                          invalid={
                            !!touched.confirmPassword &&
                            !!errors.confirmPassword
                          }
                        />
                        <Label>Confirm Password</Label>
                        <FormFeedback>
                          {touched.confirmPassword && errors.confirmPassword}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="11" className="text-center mt-3 mt-sm-5">
                      <button
                        type="submit"
                        className="button-main"
                        disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </form>
              </Formik>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
