import React from "react";
import "../forgotpassword.scss";
import { Link } from "react-router-dom";

//Assets
import check from "../../../resources/images/check-mark.png";

const Confirm = ({ email }) => {
  return (
    <div>
      <div className="row confirmed col-12 text-center">
        <h1>Confirmation Email Sent.</h1>
        <div className="confirmed-description">
          <img src={check} alt="Check" />
          <h3 className="text-wrapper">
            An email has been sent to {email} with further instructions.
          </h3>

          <Link to="/" className="button-main">
            Done
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
