import React, { useState } from "react";
import "./admin.scss";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
} from "reactstrap";
import { List, Filter, Search } from "react-feather";
import { Switch, Route, Link, useLocation } from "react-router-dom";

//Components
import Records from "./components/Records";
import Requests from "./components/Requests";
import Users from "./components/Users";

const AdminPanel = (props) => {
  const [filter, setFilter] = useState("Filter");
  const [searchText, setSearchText] = useState("");
  // const toggleNavBar = () => setCollapsed(!collapsed);
  // const handleClick = () => {};

  const location = useLocation();

  const mapTabs = () => {
    const buttons = [
      { label: "Table of Records", tab: "/admin/records" },
      { label: "User Requests", tab: "/admin/requests" },
      { label: "Authorized Users", tab: "/admin/users" },
    ];

    return buttons.map((button, index) => {
      return (
        <Link to={button.tab} key={index}>
          <button
            className={location.pathname === button.tab ? "button-active" : ""}
          >
            {button.label}
          </button>
        </Link>
      );
    });
  };

  return (
    <div className="container-fluid page page-admin">
      <div className="row justify-content-center h-100">
        <div className="col-12 my-auto">
          <Card className="table-container">
            <CardBody>
              <Card className="table-wrapper">
                <CardBody className="pb-0">
                  <h5>Admin Panel</h5>
                  <div>
                    <div className="tabs-wrapper">{mapTabs()}</div>
                    <button className="button-import">Import</button>
                  </div>
                  <Row className="pt-2 table-header">
                    <Col
                      xs="6 pr-0"
                      sm="6"
                      md="6"
                      lg="8"
                      xl="10"
                      className="pl-lg-3"
                    >
                      <form>
                        <FormGroup>
                          <div className="position-relative has-icon-left">
                            <Input
                              type="text"
                              id="iconLeft"
                              name="iconLeft"
                              className="round"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <div className="form-control-position">
                              <Search size={16} className="info" />
                            </div>
                          </div>
                        </FormGroup>
                      </form>
                    </Col>
                    <Col
                      xs="3 px-0"
                      md="3"
                      lg="2"
                      xl="1"
                      className="text-right"
                    >
                      <UncontrolledDropdown className="pr-1">
                        <DropdownToggle className="button-sort">
                          <List size={20} color="#333" />
                          <span className="xs-hidden"> Sort</span>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem>Hello</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                    <Col
                      xs="3 px-0"
                      md="3"
                      lg="2"
                      xl="1"
                      className="text-right"
                    >
                      <UncontrolledDropdown className="pr-1">
                        <DropdownToggle className="button-sort">
                          <Filter size={20} color="#333" />
                          <span className="xs-hidden text-capitalize">
                            {" "}
                            {filter}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => setFilter("name")}>
                            Name
                          </DropdownItem>
                          <DropdownItem onClick={() => setFilter("market")}>
                            Market
                          </DropdownItem>
                          <DropdownItem onClick={() => setFilter("skill")}>
                            Skills
                          </DropdownItem>
                          <DropdownItem onClick={() => setFilter("mentorship")}>
                            Mentorships
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>

                  {/*Table Here*/}
                  <Switch>
                    <Route exact component={Users} path="/admin/users" />
                    <Route exact component={Records} path="/admin/records" />
                    <Route exact component={Requests} path="/admin/requests" />
                  </Switch>
                  {/* {activeTab === "Records" && <Records />}
                  {activeTab === "Requests" && <Requests />}
                  {activeTab === "Users" && <Users />} */}
                </CardBody>
              </Card>
              <div className="row justify-content-end pagination-options">
                <div className="col-6 col-md-3 col-lg-4 col-xl-3 text-right">
                  Rows per page:{" "}
                  <select name="rows" className="select-rows">
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
