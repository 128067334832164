import React, { useState, useEffect } from "react";
import "../admin.scss";
import { Row, Col, Table } from "reactstrap";

//API
import { getUsers } from "../../../api/api";

const Users = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await getUsers();
        setUsers(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchUsers();
  }, []);

  return (
    <Row className="table-admin">
      <Col className="px-0 pt-0">
        <Table hover>
          <thead>
            <tr>
              <th>Authorized User's Name</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>*Name here*</td>
                    <td>{user.email}</td>
                    <td>*Phone Numbers*</td>
                    <td className="user-button">
                      <button>Edit</button>
                      <button>Remove</button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default Users;
