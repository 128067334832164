import React, { useEffect, useState, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./qrscanner.scss";
import QrScanner from "react-qr-scanner";
import { capitalize } from "@material-ui/core";
import { X } from "react-feather";

//Context
import withUserContext from "../../api/withContext/withUser";

const QRScanner = ({ isOpen, toggle }) => {
  const [cameraId, setCameraId] = useState("");
  const [devices, setDevices] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isDesktop = width > 768;

  const handleScan = (url) => {
    if (url) {
      window.open(url.text, "_blank");
      toggle();
    }
  };
  const previewStyle = {
    height: 280,
    width: 280,
    padding: "20px",
  };

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true, video: true },
      function (stream) {
        stream.getTracks().forEach((x) => x.stop());
        getCams();
      },
      (err) => console.log(err)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (devices.length) setCameraId(devices[0].id);
  }, [devices]);

  //Fix for unsupported browsers, source: https://stackoverflow.com/questions/28991835/firefox-navigator-getusermedia-is-not-a-function
  navigator.getUserMedia = ( navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia);

  const getCams = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        let arr = [];
        devices.forEach(function (device) {
          if (device.kind === "videoinput") {
            if (isDesktop) {
              arr.push({
                label: capitalize(device.label),
                id: device.deviceId,
              });
            } else {
              if (device.getCapabilities().facingMode[0] === "environment") {
                arr.push({
                  label: capitalize(device.label),
                  id: device.deviceId,
                });
              }
            }
          }
        });
        setDevices([...arr]);
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  };

  return (
    <Modal {...{ isOpen, toggle }} centered>
      <ModalBody className="scanner-container text-center">
        <button className="button-exit" onClick={toggle}><X/></button>
        <p>
          Place the code in the center of the square. It will be scanned
          automatically.
        </p>
        <div className="scanner-wrapper">
          {devices.length
            ? devices.map((device, index) => {
                return (
                  <Fragment key={index}>
                    {cameraId === device.id && (
                      <QrScanner
                        delay={500}
                        style={previewStyle}
                        onScan={handleScan}
                        constraints={{
                          video: {
                            deviceId: device.id,
                          },
                        }}
                      />
                    )}
                  </Fragment>
                );
              })
            : ""}
        </div>
        <p className="text-center mt-3 camera-select">
          <strong>Camera not working?</strong> Choose your camera below:
        </p>
        <select
          onChange={(e) => {
            setCameraId(e.target.value);
          }}
        >
          {devices.map((device, index) => (
            <option key={index} value={device.id}>
              {device.label}
            </option>
          ))}
        </select>
      </ModalBody>
    </Modal>
  );
};

export default withUserContext(QRScanner);
