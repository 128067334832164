import React, { useState } from "react";
import "./sale.scss";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Alert,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

const Sale = () => {
  const [
    isLoading,
    // setIsLoading
  ] = useState(false);
  const [error, setError] = useState("");

  const SaleSchema = Yup.object().shape({
    fflNumber: Yup.number().required("FFL # is required"),
    licenseNumber: Yup.string().required("Driver's License is required"),
    purchaser: Yup.string().required("Purchaser is required"),
    state: Yup.string().required("State is required"),
    dateOfSale: Yup.string().required("Date of Sale is required"),
  });

  const initialValues = {
    fflNumber: "",
    licenseNumber: "",
    purchaser: "",
    state: "",
    dateOfSale: "",
  };

  const onSubmit = (values) => {
    console.log(`Submitted`);
  };

  return (
    <div className="container-fluid page page-search">
      <div className="row justify-content-center h-100">
        <div className="col-xl-5 col-lg-8 col-md-8 col-11 my-auto">
          <div className="search-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={SaleSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Formik className="pt-2">
                  <fieldset disabled={isLoading}>
                    {error && (
                      <Alert color="warning" fade={false}>
                        Something went wrong. Please try again later.
                      </Alert>
                    )}
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Row>
                          <Col xs="12">
                            <Label>FFL Number</Label>
                            <Input
                              id="fflNumber"
                              name="fflNumber"
                              type="number"
                              required
                              className={classNames("form-control", {
                                "login-warning":
                                  !!errors.fflNumber && !!touched.fflNumber,
                              })}
                              placeholder="X-XX-XXX-XX-XX-XXXXX"
                              value={values.fflNumber}
                              onBlur={handleBlur("fflNumber")}
                              onChange={(e) => {
                                handleChange("fflNumber")(e);
                                setError(false);
                              }}
                              invalid={
                                !!touched.fflNumber && !!errors.fflNumber
                              }
                            />
                            <FormFeedback>
                              {touched.fflNumber && errors.fflNumber}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Row>
                          <Col xs="12">
                            <Label>Driver's License Number</Label>
                            <Input
                              id="licenseNumber"
                              name="licenseNumber"
                              type="text"
                              required
                              className={classNames("form-control", {
                                "login-warning":
                                  !!errors.licenseNumber &&
                                  !!touched.licenseNumber,
                              })}
                              placeholder=""
                              value={values.licenseNumber}
                              onBlur={handleBlur("licenseNumber")}
                              onChange={(e) => {
                                handleChange("licenseNumber")(e);
                                setError(false);
                              }}
                              invalid={
                                !!touched.licenseNumber &&
                                !!errors.licenseNumber
                              }
                            />
                            <FormFeedback>
                              {touched.licenseNumber && errors.licenseNumber}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Row>
                          <Col xs="12">
                            <Label>Purchaser Name</Label>
                            <Input
                              id="purchaser"
                              name="purchaser"
                              type="text"
                              required
                              className={classNames("form-control", {
                                "login-warning":
                                  !!errors.purchaser && !!touched.purchaser,
                              })}
                              placeholder=""
                              value={values.purchaser}
                              onBlur={handleBlur("purchaser")}
                              onChange={(e) => {
                                handleChange("purchaser")(e);
                                setError(false);
                              }}
                              invalid={
                                !!touched.purchaser && !!errors.purchaser
                              }
                            />
                            <FormFeedback>
                              {touched.purchaser && errors.purchaser}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Row>
                          <Col xs="12">
                            <Label>State</Label>
                            <Input
                              id="state"
                              name="state"
                              type="text"
                              required
                              className={classNames("form-control", {
                                "login-warning":
                                  !!errors.state && !!touched.state,
                              })}
                              placeholder=""
                              value={values.state}
                              onBlur={handleBlur("state")}
                              onChange={(e) => {
                                handleChange("state")(e);
                                setError(false);
                              }}
                              invalid={!!touched.state && !!errors.state}
                            />
                            <FormFeedback>
                              {touched.state && errors.state}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mb-4">
                      <FormGroup>
                        <Row>
                          <Col xs="12">
                            <Label>Date of Sale</Label>
                            <Input
                              id="dateOfSale"
                              name="dateOfSale"
                              type="text"
                              required
                              className={classNames("form-control", {
                                "login-warning":
                                  !!errors.dateOfSale && !!touched.dateOfSale,
                              })}
                              placeholder=""
                              value={values.dateOfSale}
                              onBlur={handleBlur("dateOfSale")}
                              onChange={(e) => {
                                handleChange("dateOfSale")(e);
                                setError(false);
                              }}
                              invalid={
                                !!touched.dateOfSale && !!errors.dateOfSale
                              }
                            />
                            <FormFeedback>
                              {touched.dateOfSale && errors.dateOfSale}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <FormGroup>
                      <Col md="12" className="text-center">
                        <button
                          type="submit"
                          color="primary"
                          block="true"
                          className="button-main"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Formik>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sale;
