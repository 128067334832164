import React, { useState } from "react";
import "../account.scss";
import { User } from "react-feather";
import {
  Col,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Alert,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

//API
import { userLogin, changePassword } from "../../../api/api";

//Context
import withUserContext from "../../../api/withContext/withUser";

const ChangePassword = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const PasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf(
          [Yup.ref("newPassword")],
          "Password and Confirm Password must match"
        )
        .required("Confirm Password is required"),
    }),
  });

  const initialValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    try {
      await userLogin({
        username: user.email,
        password: values.password,
      });

      await changePassword(user._id, {
        password: values.newPassword,
        confirm_password: values.confirmPassword,
      });

      setSuccess(true);
      resetForm();
    } catch (e) {
      setError("The current password your entered is incorrect.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid page page-account">
      <div className="row justify-content-center h-100">
        <div className="col-xl-5 col-lg-6 col-md-8 col-11 my-auto text-center">
          <div>
            <User color="white" size="50" />
          </div>
          <h2>Change Password</h2>
          <hr />
          <div className="mt-5">
            <Formik
              initialValues={initialValues}
              validationSchema={PasswordSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Formik className="pt-2">
                  <form
                    disabled={isLoading}
                    className="wrapper-change-password"
                  >
                    {success && (
                      <Alert color="success" fade={false}>
                        Password has been updated!
                      </Alert>
                    )}
                    {error && (
                      <Alert color="warning" fade={false}>
                        {error}
                      </Alert>
                    )}
                    <Row className="justify-content-center py-md-5">
                      <Col xs="10" md="8" className="mb-4">
                        <FormGroup>
                          <Label>Current Password</Label>
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            required
                            className={classNames("form-control", {
                              "login-warning":
                                !!errors.password && !!touched.password,
                            })}
                            placeholder=""
                            value={values.password}
                            onBlur={handleBlur("password")}
                            onChange={(e) => {
                              handleChange("password")(e);
                              setError("");
                              setSuccess(false);
                            }}
                            disabled={isLoading}
                            invalid={!!touched.password && !!errors.password}
                          />
                          <FormFeedback>
                            {touched.password && errors.password}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="10" md="8" className="mb-4">
                        <FormGroup>
                          <Label>New Password</Label>
                          <Input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            required
                            className={classNames("form-control", {
                              "login-warning":
                                !!errors.newPassword && !!touched.newPassword,
                            })}
                            placeholder=""
                            value={values.newPassword}
                            onBlur={handleBlur("newPassword")}
                            onChange={(e) => {
                              handleChange("newPassword")(e);
                              setError("");
                              setSuccess(false);
                            }}
                            disabled={isLoading}
                            invalid={
                              !!touched.newPassword && !!errors.newPassword
                            }
                          />
                          <FormFeedback>
                            {touched.newPassword && errors.newPassword}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="10" md="8" className="mb-4">
                        <FormGroup>
                          <Label>Confirm Password</Label>
                          <Input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            required
                            className={classNames("form-control", {
                              "login-warning":
                                !!errors.confirmPassword &&
                                !!touched.confirmPassword,
                            })}
                            placeholder=""
                            value={values.confirmPassword}
                            onBlur={handleBlur("confirmPassword")}
                            onChange={(e) => {
                              handleChange("confirmPassword")(e);
                              setError("");
                              setSuccess(false);
                            }}
                            disabled={isLoading}
                            invalid={
                              !!touched.confirmPassword &&
                              !!errors.confirmPassword
                            }
                          />
                          <FormFeedback>
                            {touched.confirmPassword && errors.confirmPassword}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-5">
                      <Col md="8" className="text-center">
                        <button
                          type="submit"
                          className="button-main"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </form>
                </Formik>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(ChangePassword);
