import React, { useEffect } from "react";
import "./home.scss";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

//Context
import withUserContext from "../../api/withContext/withUser";

const Home = ({ user }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      history.push("/directory");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (user) return "";

  return (
    <div className="container-fluid page page-home">
      <div className="row justify-content-center h-100">
        <div className="col-xl-4 col-lg-6 col-md-8 col-11 my-auto">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <Link
                to={
                  location.state
                    ? { pathname: "/login", state: location.state }
                    : "/login"
                }
              >
                <button className="button-main w-100">Login</button>
              </Link>
              <Link to="/sign-up">
                <button className="button-main w-100">Sign Up</button>
              </Link>
              <Link to="/forgot-password">
                <button className="button-sublink">Forgot Password?</button>
              </Link>
              <div className="small-links">
                <Link to="/terms" className="text-right">
                  <button className="button-sublink">Terms</button>
                </Link>
                <Link to="/privacy-policy">
                  <button className="button-sublink">Privacy</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(Home);
