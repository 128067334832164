import React from "react";
import "./success.scss";
import { Check } from "react-feather";

const Success = () => {
  return (
    <div className="container page page-success">
      <div className="row justify-content-center h-100">
        <div className="col-xl-6 col-lg-6 col-md-8 col-9 my-auto">
          <h1 className="text-center text-white">Sign Up Request Successful</h1>
          <p className="text-center text-white">
            <Check color="white" size={50} />
            <br />
            {/* Your sign up request has been successfully sent to an administrator
            for approval. */}
            You may now login with your newly registered account.
            {/* Be sure to use the email and password you input during your registration. */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
