import React, { useState } from "react";
import { Formik } from "formik";
import {
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  Alert,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import classNames from "classnames";

//Assets
import lock from "../../../resources/images/lock.png";

//API
import { forgotPassword } from "../../../api/api";

const Form = ({ emailConfirmed, setEmailConfirmed, setEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      await forgotPassword(values);
      setEmailConfirmed(!emailConfirmed);
      setEmail(values.email);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });

  return (
    <div>
      <h1 className="text-center">Forgot your password?</h1>
      <div className="forgot-description">
        <img src={lock} alt="Lock" />
        <h3 className="text-wrapper">
          Enter your email address and we'll send you a link to reset your
          password
        </h3>
      </div>
      <div className="row email-wrapper">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={emailSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Formik className="pt-2">
              <form disabled={isLoading}>
                {error && (
                  <Alert color="warning" fade={false}>
                    Something went wrong. Please try again.
                  </Alert>
                )}
                <Row>
                  <Col md="12" className="mb-4">
                    <FormGroup>
                      <Label>Email Address</Label>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        required
                        className={classNames("form-control", {
                          "login-warning": !!errors.email && !!touched.email,
                        })}
                        placeholder=""
                        value={values.email}
                        onBlur={handleBlur("email")}
                        onChange={(e) => {
                          handleChange("email")(e);
                          setError(false);
                        }}
                        disabled={isLoading}
                        invalid={!!touched.email && !!errors.email}
                      />
                      <FormFeedback>
                        {touched.email && errors.email}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Col md="12" className="text-center">
                        <button
                          type="submit"
                          className="button-main"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          Reset Password
                        </button>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </Formik>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Form;
