import React from "react";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";

//Components
import Header from "../components/header";

//Context Probiders
import Authentication from "../api/context/user-context";

const App = () => {
  return (
    <BrowserRouter>
      <Authentication>
        <Header />
        <Routes />
      </Authentication>
    </BrowserRouter>
  );
};

export default App;
