export const getDashedDate = (date) => {
  const currentDate = new Date(date);

  let formattedDate = currentDate.getDate();

  let formattedMonth = currentDate.getMonth() + 1;
  
  if(formattedMonth < 10) {
    formattedMonth = `0${formattedMonth}`;
  }

  if (formattedDate < 10) {
    formattedDate = `0${formattedDate}`;
  }

  return `${currentDate.getFullYear()}-${formattedMonth}-${formattedDate}`;
};

export const getSlashedDate = (date) => {
  const currentDate = new Date(date);

  let formattedDate = currentDate.getDate();

  let formattedMonth = currentDate.getMonth() + 1;
  
  if(formattedMonth < 10) {
    formattedMonth = `0${formattedMonth}`;
  }

  if (formattedDate < 10) {
    formattedDate = `0${formattedDate}`;
  }

  return `${currentDate.getFullYear()}/${formattedMonth}/${formattedDate}`;
};

export const getTime = (currentTime) => {
  const time = new Date(currentTime);
  let hours = time.getHours();
  let minutes = time.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (!hours || !minutes) {
    return "-";
  }

  return `${hours}:${minutes}`;
};